.about-me-descriptor {
    border: solid;
    position: relative;
    padding: 3vh 1vh 1vh 2vh;
    margin-top: 5vh;
}

.about-me-descriptor-outer {
    position: absolute;
    top: -3vh;
    padding: 0 1vh 0 1vh;
    background-color: #E1F2F7;
}

#about-me {
    width: 92vw;
    margin-top: 15vh;
}

#about-container {
    display: flex;
    align-items: flex-start;
}

#about-me-info {
    height: 100%;
    width: 50vw;
    margin-left: 3.75vw;
    margin-right: 3.75vw;
}

#about-me-divider {
    height: 100%;
    width: 0.5vw;
    margin-right: 3.75vw;
    background-color: #343478;
}

#about-me-box {
    width: 24.5vw;
    min-height: 90%;
    border: 0.25vw solid #343478;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2vh;
}

#quick-facts {
    height: 90%;
    width: 95%;
}

#quick-facts > h4 {
    padding-left: 1vh;
}

@media screen and (max-width: 1500px) {
    #about-container {
        flex-direction: column;
        align-items: center;
    }

    #about-me-info {
        height: 80%;
        width: 80vw;
        margin-right: 0;
    }

    #about-me-divider {
        height: 0.25vh;
        width: 90vw;
        margin: 3.75vw 0 3.75vw 0;
        flex-shrink: 0;
    }

    #about-me-box {
        width: 80vw;
        padding-bottom: 2vh;
    }
}

@media screen and (max-width: 900px) and (orientation: portrait) {
    .about-me-descriptor {
        margin-top: 3vh;
    }

    .about-me-descriptor-outer {
        top: -2.5vh;
    }
}