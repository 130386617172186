* {
  font-weight: 100;
}

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #E1F2F7;
}

h1 {
  font-size: 10vh;
  margin: 1vh 0 1vh 0;
}

h2 {
  font-size: 6vh;
  margin: 0.6vh 0 0.6vh 0;
}

h3 {
  font-size: 3vh;
  margin: 0.3vh 0 0.3vh 0;
}

h4 {
  font-size: 2vh;
  margin: 0.2vh 0 0.2vh 0;
}

@media screen and (max-width: 900px) and (orientation: portrait) {
  h1 {
    font-size: 6vh;
    margin: 0.6vh 0 0.6vh 0;
  }
  
  h2 {
    font-size: 3vh;
    margin: 0.3vh 0 0.3vh 0;
  }
  
  h3 {
    font-size: 2vh;
    margin: 0.2vh 0 0.2vh 0;
  }

  h4 {
    font-size: 1.5vh;
    margin: 0.15vh 0 0.15vh 0;
  }
}

@media screen and (max-height: 500px) and (orientation: landscape) {
  h3 {
    font-size: 4vh;
    margin: 0.4vh 0 0.4vh 0;
  }
  
  h4 {
    font-size: 3vh;
    margin: 0.3vh 0 0.3vh 0;
  }
}