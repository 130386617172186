#project-box {
    position: relative;
    width: 90vw;
    margin: 2vw 0 2vw 0;
    flex-shrink: 0;
    display: flex;
}


#project-box-left {
    width: 30vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

#project-box-thumbnail {
    max-height: 30vh;
    max-width: 100%;
    box-shadow: 0.2vw 0.2vw 0.1vw #343478;
    
}

#project-box-thumbnail:hover {
    cursor: pointer;
    transform: translate(0, -1vw);
    box-shadow: 0.2vw 0.7vw 0.6vw #343478;
    transition-duration: 0.5s;
}

#project-box-right {
    flex: 1;
    height: 100%;
    margin-left: 2vw;
}

#project-title-box {
    display: flex;
    align-items: center;
}

#project-box-link {
    height: 4vh;
    padding-top: 1vh;
    padding-left: 1vw;
    cursor: pointer;
}

.project-description {
    padding-bottom: 2vh;
}

@media screen and (max-width: 1600px) {
    #project-box {
        width: 90vw;
    }
}

@media screen and (max-width: 1300px) and (orientation: landscape) {
    .project-description {
        padding-bottom: 0;
        font-size: 2vh;
        margin: 2vh 0 0.2vh 0;
    }
    
    #project-box {
        height: 30vh;
    }

    #project-box-left {
        width: 30vh;
        height: 30vh;
    }

    #project-title {
        font-size: 3vh;
        margin: 0.3vh 0 0.3vh 0;
    }
}

@media screen and (max-height: 600px) and (orientation: landscape) {
    .project-description {
        padding-bottom: 0;
        font-size: 3vh;
        margin: 2vh 0 0.2vh 0;
    }
    
    #project-box {
        height: 40vh;
    }

    #project-box-left {
        width: 40vh;
        height: 40vh;
    }

    #project-title {
        font-size: 5vh;
        margin: 0.3vh 0 0.3vh 0;
    }

    
    #project-box-link {
        height: 10vh;
    }
}

@media screen and (max-width: 1300px) and (orientation: portrait) {
    .project-description {
        padding-bottom: 0;
        font-size: 2vw;
        margin: 2vw 0 0.2vw 0;
    }
    
    #project-box {
        height: 30vw;
    }

    #project-box-left {
        width: 30vw;
        height: 30vw;
    }

    #project-title {
        font-size: 3vw;
        margin: 0.3vw 0 0.3vw 0;
    }

    #project-box-link {
        height: 6vw;
    }
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    .project-description {
        padding-bottom: 0;
        font-size: 3vw;
    }
    
    #project-box {
        height: 60vw;
    }

    #project-box-left {
        height: 60vw;
    }

    #project-title {
        font-size: 5vw;
    }

    #project-box-link {
        height: 8vw;
    }
}