.popup-invisible {
    opacity: 0;
    pointer-events: none;
    transition-duration: 1s;
}

.popup-visible {
    opacity: 1;
    transition-duration: 1s;
    pointer-events: all;
}

.popup-icon-container {
    max-width: 50px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.popup-icon-container:hover {
    cursor: pointer;
}

.popup-icon-invisible {
    opacity: 0;
    pointer-events: none;
}

.popup-icon-visible {
    opacity: 1;
    pointer-events: all;
}

.icon-div {  
    width: 50px;
    height: 4px;
    background-color: #fcfeff;
}

.diagonal-div-one {
    transform: rotate(-45deg);
}

.diagonal-div-two {
    transform: rotate(45deg);
}

.arrow-bottom {
    margin-top: 29px;
}

.x-bottom {
    margin-top: -4px;
}

#popup {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
}

#popup-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#x-icon {
    margin-left: 64vw;
}

#popup-pic {
    width: 60vw;
    max-height: 80vh;
    margin: 0 4vw 0 4vw;
}

#popup-numbers {
    color: #fcfeff;
}
