.skills-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2vh;
}

.logo-container {
    width: 160px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.skills-logo-box {
    width: 6vw;
    min-width: 80px;
    height: 6vw;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.skills-logo {
    width: 4vw;
    min-width: 70px;
    height: 4vw;
    min-height: 70px;
    filter: invert(20%) sepia(35%) saturate(2294%) hue-rotate(216deg) brightness(86%) contrast(90%);
}

.skills-logo-big {
    width: 4.75vw;
    min-width: 85px;
    height: 4.75vw;
    min-height: 85px;
    filter: invert(20%) sepia(35%) saturate(2294%) hue-rotate(216deg) brightness(86%) contrast(90%);
}

#skills {
    width: 92vw;
    margin-top: 15vh;
}

@media screen and (max-width: 900px) {
    .skills-container {
        justify-content: center;
    }

    .logo-container {
        width: 150px;
        height: 150px;
    }

    .skills-logo-box {
        width: 85px;
        height: 85px;
    }
    
    .skills-logo {
        width: 75px;
        height: 75px;
    }

    .skills-logo-big {
        width: 80px;
        height: 80px;
    }
}