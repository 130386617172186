

.sun-invisible {
    top: 110vh;
}

.sun-visible {
    top: 47vh;
    transition-duration: 3s;
    transition-timing-function: ease-out;
}

.home-page-title {
    justify-self: flex-start;
    margin-left: 4vw;
}

.invisible {
    opacity: 0;
}

.section-invisible {
    opacity: 0;
    transform: translate(0, 2vh);
}

.fast-invisible {
    opacity: 0;
    transition-duration: 0.5s;
}

.slow-invisible {
    opacity: 0;
    transition-duration: 2s;
}

.fast-visible {
    opacity: 1;
    transition-duration: 0.5s;
}

.medium-visible {
    opacity: 1;
    transition-duration: 1s;
}

.slow-visible {
    opacity: 1;
    transition-duration: 2s;
}

.navbar-tab-box {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-box-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-box-inner:hover {
    cursor: pointer;
}

.navbar-text-offset {
    text-align: center;
    opacity: 0;
    transform: translate(0, -50%);
}

.navbar-text-reset {
    text-align: center;
    opacity: 1;
    transition-timing-function: cubic-bezier(0.64, 0.57, 0.7, 1.94);
    transform: translate(0, 0);
    transition-duration: 0.5s;
}

.navbar-selector{
    position: absolute;
    bottom: -5px;
    width: 10%;
    height: 5px;
    background-color: #9594cf;
}

.selector-bottom {
    z-index: 6;
    background-color: #9594cf;
}

.selector-top {
    z-index: 7;
    background-color: #343478;
}

#home-page {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0;
    font-family: "Gentium Plus", serif;
    color: #343478;
}

#home-page-mountain {
    position: absolute;
    width: 100vw;
    bottom: 0;
    z-index: 3;
}

#home-blue-gradient {
    position: absolute;
    width: 100vw;
    height: 20vh;
    top: 82vh;
    z-index: 4;
    background: linear-gradient(180deg, rgba(150, 149, 208, 0), rgba(150, 149, 208, 60%) 60%, #E1F2F7 100%);
}

#home-yellow-gradient {
    position: absolute;
    width: 100vw;
    height: 20vh;
    top: 82vh;
    z-index: 4;
    background: linear-gradient(180deg, rgba(255, 248, 116, 0), rgba(255, 248, 116, 50%) 50%, #E1F2F7 100%);
}

#home-page-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12vh 0 50vh 0;
    z-index: 3;
}

#home-page-sun {
    position: absolute;
}

#home-page-sun-inside {
    position: absolute;
    height: 22vh;
    width: 22vh;
    top: -11vh;
    left: -11vh;
    border-radius: 50%;
    z-index: 2;
    background-color: #FFF974;
}

#home-page-sun-outside {
    position: absolute;
    height: 66vh;
    width: 66vh;
    top: -33vh;
    left: -33vh;
    border-radius: 50%;
    z-index: 2;
    background: radial-gradient(50% 50% at 50% 50%, #F8FFA5 55%, rgba(255, 255, 255, 0) 100%);
}

#home-page-get-started {
    height: 6vh;
    width: 20vh;
    margin-top: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5vh solid #343478;
    border-radius: 1vh;
}

#home-page-get-started:hover {
    cursor: pointer;
}

#navbar {
    position: fixed;
    display: flex;
    height: 10vh;
    width: 100vw;
    top: 0;
    z-index: 5;
    background-color: #E1F2F7;
    border-bottom: 5px solid #c2c1e9;
}

#navbar-tabs {
    height: 100%;
    width: 75%;
    display: flex;
}

#navbar-resume-box {
    height: 100%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#navbar-linked-in {
    height: 5vh;
    width: 5vh;
    margin-left: 3vw;
    border: 0.5vh solid #343478;
    border-radius: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#navbar-linked-in-logo {
    height: 5.25vh;
    width: 5.25vh;
    filter: invert(20%) sepia(35%) saturate(2294%) hue-rotate(216deg) brightness(86%) contrast(90%);
}

#navbar-resume-link {
    text-decoration: none;
}

#navbar-resume {
    height: 5vh;
    width: 12vh;
    background-color: #fcfeff;
    border: 0.5vh solid #343478;
    border-radius: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#projects {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#projects-title {
    width: 92vw;
    margin-top: 15vh;
}

#pixel-myth-logo {
    margin-top: 8vh;
}

@media screen and (max-width: 800px) {
    #pixel-myth-logo {
        max-width: 90vw;
    }
}

@media screen and (max-width: 900px) and (orientation: portrait) {
    #navbar-resume {
        height: 5vw;
        width: 12vw;
        border: 0.5vw solid #343478;
        border-radius: 1vw;
    }
    
    #navbar-linked-in {
        height: 5vw;
        width: 5vw;
        border: 0.5vw solid #343478;
        border-radius: 1vw;
    }
    
    #navbar-linked-in-logo {
        height: 5.25vw;
        width: 5.25vw;
    }
    
    #home-page-title {
        margin: 20vh 0 42vh 0;
    }
}

@media screen and (max-width: 500px) and (orientation: portrait) {
    #navbar-resume {
        height: 7vw;
        width: 15vw;
        border: 0.75vw solid #343478;
        border-radius: 1.5vw;
    }
    
    #navbar-linked-in {
        height: 7vw;
        width: 7vw;
        border: 0.75vw solid #343478;
        border-radius: 1.5vw;
    }

    #navbar-linked-in-logo {
        height: 7.25vw;
        width: 7.25vw;
    }

    #navbar-tabs {
        width: 60%;
    }

    #navbar-resume-box {
        width: 40%;
    }
}

@media screen and (max-height: 500px) and (orientation: landscape) {
    #home-page-title {
        margin: 10vh 0 52vh 0;
    }
}

