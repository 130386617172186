.form-box {
    height: 3vh;
    background-color: #fcfeff;
    border: 0.5vh solid #343478;
    border-radius: 1vh;
    font-size: 2vh;
}

.form-box-short {
    width: 90%;
}

.form-box-long {
    width: 100%;
}

.form-big-container {
    grid-column: 1 / 3;
}

.contact-divider {
    width: 70vw;
    height: 0.25vw;
    flex-shrink: 0;
    background-color: #343478;
    margin: 8vh 0 8vh 0;
}

.submit-text-invisible {
    opacity: 0;
    transition-duration: 2s;
}

.submit-text-visible-success {
    opacity: 1;
    color: green;
    transition-duration: 2s;
}

.submit-text-visible-failure {
    opacity: 1;
    color: red;
    transition-duration: 2s;
}

.check-me-out-logo-container {
    width: 10vh;
    height: 10vh;
    border: 0.5vh solid #343478;
    box-shadow: 0 0.2vw 0.1vw #343478;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-me-out-logo-container:hover {
    transform: translate(0, -0.5vw);
    box-shadow: 0 0.5vw 0.4vw #343478;
    transition-duration: 0.5s;
}

.check-me-out-image {
    width: 7vh;
    height: 7vh;
    filter: invert(20%) sepia(35%) saturate(2294%) hue-rotate(216deg) brightness(86%) contrast(90%);
}

#home-page-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#contact-form-title {
    width: 92vw;
    margin-top: 15vh;
}

#contact-form-inputs {
    width: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 9vh 9vh 35vh 7vh;
}

#form-email, #form-email-label {
    margin-left: 10%;
}

#form-message {
    height: auto;
    resize: none;
}


#form-submit {
    width: 15vh;
    height: 6vh;
    justify-self: end;
    background-color: #c2c1e9;
    border: 0.5vh solid #343478;
    border-radius: 1vh;
    grid-column: 2 / 3;
    font-size: 3vh;
}

#form-submit:hover {
    cursor: pointer;
}

#check-me-out-box {
    width: 100vh;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}


#check-me-out-logos {
    width: 40vh;
    display: flex;
    justify-content: space-between;
    margin-top: 4vh;
}

@media screen and (max-width: 1100px) {
    .contact-divider {
        width: 90vw;
    }

    #contact-form-inputs {
        display: flex;
        flex-direction: column;
        width: 80vw;
    }

    #form-email, #form-email-label {
        margin-left: 0;
    }

    .form-box-short {
        width: 100%;
    }
}

@media screen and (max-width: 900px) and (orientation: portrait) {
    #check-me-out-box {
        height: 30vh;
    }
}