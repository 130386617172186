.react-pdf__Page__canvas{
    max-width: 90vw !important;
    max-height: 90vh !important;
    width: auto !important;
    height: auto !important;
}

#resume {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#resume-text {
    position: absolute;
    top: 2vh;
    left: 2vw;
    color: #343478;
}

#resume-mountain {
    position: absolute;
    width: 100vw;
    bottom: 0;
}